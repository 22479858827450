<script setup>
// import moment from 'moment';
import { useStore} from 'vuex';
import { useI18n } from 'vue-i18n';
import SelectButton from './MatchesModal/SelectButton';
import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import ZdhcServiceV5 from '../../services/Zdhc/ZdhcServiceV5';
// import ZdhcProductService from '../../services/ZdhcProductService';
import SuggestionService from '../../services/SuggestionService';
import useMatchAlgorithm from '../../composables/useMatchAlgorithm';
import SnackbarComposables from '../../composables/SnackbarComposables';
import IncheckReferenceService from '../../services/IncheckReferenceService';
import { ref, watch, onBeforeMount, computed, defineEmits, defineProps, onMounted, onUnmounted } from 'vue';
/* import type { UseFuseOptions } from '.' */
import { useFuse } from '@vueuse/integrations/useFuse';
/* Emits */
const emits = defineEmits(['closeModal', 'newSuggestion', 'noMatch']);
/* Props */
const props = defineProps({
    selectedDate: {required: true},
    parent_product:{required:true}
});
/* Data */
const store = useStore();
const {t} = useI18n();
const matching_status = ref('loading');
const submitStatus = ref('void');
const notMatchStatus = ref('void');
const products = ref([]);
const active_match = ref(null);
const input_search = ref('');
const splittedInputSearch = ref([]);
const searchTimer = ref(null);
const { addSnackbar } = SnackbarComposables();
const { assignProduct, searchMatchesFirstPhase, searchMatchesSecondPhase, searchMatchesThirdPhase } = useMatchAlgorithm();
const { storeNewProduct} = ZdhcServiceV2();
const { searchZdhcProductByZdhcPid, searchZdhcProductByWord} = ZdhcServiceV5();
const { setNoMatchInIncheckReference } = IncheckReferenceService();
const { /* searchSuggestionByName, */ storeSuggestion } = SuggestionService();
/* Watch */
watch( 
    () => input_search.value,
    () => 
    {
        clearTimeout(searchTimer.value);
        searchTimer.value = setTimeout( () => searchProduct(), 800);
    }
);
/* Computed */
const setNoMatchBadgeClasses = computed( () => 
{
    if(props.parent_product.incheck_reference?.no_match_id)
    {
        if(props.parent_product.incheck_reference?.previous_results_at) return 'bg-red-50 text-red-500';
        else return 'bg-purple-50 text-purple-500';
    }
    else return 'bg-purple-50 text-purple-500';
});
const showNoMatchButton = computed( () => 
{
    if(!props.parent_product.incheck_reference?.no_match_id) return true;
    else if(!props.parent_product.incheck_reference?.previous_results_at) return true;
    else return false;
});
const setSubmitContent = computed( () => 
{
    switch(submitStatus.value)
    {
        
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return t('matchesModal.associate', store.state.locale);
    }
});
const setSubmitClasses = computed( () =>
{
    switch(submitStatus.value)
    {
        case 'void': case 'loading': default:
            return 'bg-blue-500 hover:bg-blue-300';
        case 'error':
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
const setNoMatchButtonClasses = computed( () =>
{
    switch(notMatchStatus.value)
    {
        case 'void': case 'loading': case 'error': default:
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
const setNoMatchButtonContet = computed( () => 
{
    switch(notMatchStatus.value)
    {
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return 'No match';
    }
});
/* Methods */
// STEP 1
const init = async() =>
{
    matching_status.value = 'loading';
    assignProduct(props.parent_product)
    .then( () => 
    {
        searchMatchesFirstPhase()
        .then(firstRes => 
        {
            if(firstRes.length)
            {
                sortResults(firstRes);
                return matching_status.value = 'void';
            }
            else 
            {
                searchMatchesSecondPhase()
                .then( secondRes => 
                {
                    if(secondRes.length)
                    {
                        sortResults(secondRes);
                        return matching_status.value = 'void';
                    }
                    else 
                    {
                        searchMatchesThirdPhase()
                        .then( thirdRes => 
                        {
                            if(thirdRes.length) sortResults(thirdRes);
                            return matching_status.value = 'void';
                        })
                    }
                })
                .catch( () => matching_status.value = 'error');
            }
        })
        .catch( () => matching_status.value = 'error');
    })
    .catch( () => matching_status.value = 'error');
};
// General
const setActiveMatch = (match) => 
{  
    active_match.value = match;
};
const associateProduct = async () => 
{
    let associatedProduct;
    if(props.parent_product.incheck_reference?.suggestion) associatedProduct = props.parent_product.incheck_reference.suggestion.zdhc_product_id;
    else if (props.parent_product.incheck_reference?.zdhc_product_id) associatedProduct = props.parent_product.incheck_reference.zdhc_product_id;
    else associatedProduct = 0;
    
    if(!active_match.value) return addSnackbar('warning', t('product.associate.noProduct', store.state.locale));
    else if(active_match.value.id === associatedProduct) return addSnackbar('warning', t('product.associate.anotherProduct', store.state.locale));
    else 
    {
        submitStatus.value = 'loading';
        let p;
        if(!active_match.value.fullProduct)
        {
            p = {
                ProductID:  active_match.value.id,
                translated_names:  active_match.value.translated_names,
                productGUID:  active_match.value.guid,
                reference_date: props.parent_product.incheck_reference.reference_date || null,
                OrganizationCountry:  active_match.value.data?.organization_country || null,
                registered:  active_match.value.data?.registered ? active_match.value.data?.registered : (active_match.value.data?.source ? active_match.value.data?.source : null),
                LastUpdatedDate:  active_match.value.data?.zdhc_last_updated_at || null,
                productCode:  active_match.value.data?.code || null,
                OtherName:  active_match.value.data?.other_name || null,
                productOtherName:  active_match.value.data?.product_other_name || null,
                zdhcPID:  active_match.value.data?.pid || null,
                zdhcAID:  active_match.value.data?.aid || null,
                formulatorGUID: active_match.value.formulator?.guid || null,
                formulatorName: active_match.value.formulator?.name || null,
                supplierAID: active_match.value.formulator?.supplierAID || null,
                ProductCertifications: active_match.value.certifications || []
            };
        }
        else 
        {
            active_match.value.fullProduct.reference_date = props.parent_product.incheck_reference.reference_date;
            p = active_match.value.fullProduct;
        }
        const res = await storeSuggestion(props.parent_product.id, p);
        if(res)
        {
            if('status' in res && res.status == 'error')
            {
                submitStatus.value = 'error';
                setTimeout( () => submitStatus.value = 'void', 2000);
                if('message' in res && res.message == 'ALREADY_ASSOCIATED')
                {
                    return addSnackbar('error', t('matchesModal.productAlreadyAssociated', store.state.locale));
                }
                return addSnackbar('error', 'Error');
            }
            submitStatus.value = 'success';
            setTimeout( () => submitStatus.value = 'void', 2000);
            addSnackbar('success', t('matchesModal.successfulAssociation', store.state.locale));
            return emits('newSuggestion', res.suggestion);
        }
        else
        { 
            addSnackbar('error', 'none');
            submitStatus.value = 'error';
            setTimeout( () => submitStatus.value = 'void', 2000);
        }
    }
};
const setNoMatch = async () => 
{
    let guids = [];
    notMatchStatus.value = 'loading';
    products.value.forEach(p => guids.push(p.guid));
    const formulatorGuid = props.parent_product.name.formulator ? props.parent_product.name.formulator.guid : null;
    const res = await storeNewProduct(props.parent_product.name, formulatorGuid);
    if(res.guid)
    {
        
        const noMatchRes = await setNoMatchInIncheckReference(props.parent_product.incheck_reference.id, {guid: res.guid, new_results: guids});
        if(noMatchRes)
        {
            notMatchStatus.value = 'success';
            setTimeout( () => notMatchStatus.value = 'void', 2000);
            return emits('noMatch', res.guid);
        }
    }
    else 
    {
        notMatchStatus.value = 'error';
        setTimeout( () => notMatchStatus.value = 'void', 2000);
    }
};
const searchProduct = async () => 
{
    if(input_search.value.length)
    {
        splittedInputSearch.value = input_search.value.split(' ');
        matching_status.value = 'loading';
        let res;
        let x = 1;
        if(x == 1) res = await searchZdhcProductByWord(input_search.value);
        else res = await searchZdhcProductByZdhcPid('P102NT35');
        if(res)
        {
            res = res.filter(p => 
            {
                return (p.formulator_name !== 'No match Formulator');
            });
            sortResults(res);
        }
        matching_status.value = 'void';
    }
    else return init();
};
const advancedSearch = async() => 
{
    if(splittedInputSearch.value.length > 1)
    {
        let counter = 0;
        matching_status.value = 'loading';
        for(const word of splittedInputSearch.value)
        {
            let zdhcRes = await searchZdhcProductByWord(word);
            if(zdhcRes) 
            {
                counter +=1;
                if(zdhcRes.length) 
                {
                    zdhcRes = zdhcRes.filter(p => 
                    {
                        return p.formulator_name !== 'No match Formulator';
                    });
                    sortResults(zdhcRes);
                }
            }
            if(counter === splittedInputSearch.value.length) matching_status.value = 'void';
        }
    }
};
const sortResults = (data) => 
{
    data = data.map( el => { return {...el, splitted_name: el.name.split(' ') }});
    
    let search = input_search.value ? input_search.value : props.parent_product.name;
    const { results } = useFuse(search, data, {
        fuseOptions: {
            keys: ['splitted_name'],
            isCaseSensitive: false,
            threshold: undefined,
            includeScore: true,
            includeMatches: true,
            minMatchCharLength: 3
        },
    });
    products.value = [];
    results.value.forEach(el => products.value.push(el.item));
    data.forEach(el => {if(!products.value.some(p => p.guid == el.guid )) products.value.push(el)});
};
/* Hooks */
onBeforeMount( () => 
{
    // Setting active_match
    if(props.parent_product.incheck_reference.suggestion) active_match.value = props.parent_product.incheck_reference.suggestion.zdhc_product;
    else if(props.parent_product.incheck_reference.match) active_match.value = props.parent_product.incheck_reference.match;
    init();
});
onMounted( () => document.body.classList.add('overflow-hidden'));
onUnmounted( () => document.body.classList.remove('overflow-hidden'));
</script>

<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3 text-blue-500">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="emits('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <div class="flex flex-col space-y-1">
                    <div class="w-full flex items-center justify-start space-x-3">
                        <h1 class="text-xl">
                            {{ $t('matchesModal.title', $store.state.locale) }}: {{ parent_product.name }}
                        </h1>
                        <span 
                            v-if="parent_product.incheck_reference?.no_match_id"
                            class="py-1 px-2 text-xs rounded-full flex items-center justify-center whitespace-nowrap"
                            :class="setNoMatchBadgeClasses">
                            No match
                        </span>
                    </div>
                    <h4 class="text-lg">
                        {{ $t('matchesModal.supplier', $store.state.locale) }}: {{ parent_product.formulator ? parent_product.formulator.name : (parent_product.formulator_name ? parent_product.formulator_name : '') }}
                    </h4>
                </div>
                <div class="flex flex-col space-y-5 dark:text-gray-200">
                    <div class="flex items-center justify-end w-full">
                        <input v-model="input_search" type="text" 
                            class="w-full pl-4 h-10 rounded border hover:border-blue-500 focus:border-blue-500 duration-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                            :placeholder="`${$t('matchesModal.searchPlaceholder', $store.state.locale)}...`" />
                    </div>
                    <div v-if="matching_status !== 'loading' && matching_status !== 'error'" class="w-full flex items-center justify-between">
                        <p  class="text-lg">
                            {{ $t('matchesModal.results', $store.state.locale) }}: 
                            <span class="text-blue-500 dark:text-blue-400">{{ products.length }}</span>
                        </p>
                    </div>
                    <div class="flex items-center justify-center w-full relative overflox-x-hidden overflow-y-auto overscroll-y-contain h-56">
                        <div 
                            v-if="matching_status === 'loading'" 
                            class="flex w-full items-center justify-center">
                            <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                        </div>
                        <div 
                            v-else-if="matching_status === 'error'" 
                            class="flex w-full items-center justify-center">
                            <span class="text-4xl text-red-500">
                                Something went wrong
                            </span>
                        </div>
                        <template v-else>
                            <div 
                                v-if="!products.length" 
                                class="flex w-full items-center justify-center">
                                {{ $t('matchesModal.noMatches', $store.state.locale) }}
                            </div>
                            <div
                                v-else 
                                class="w-full h-full max-h-[14rem] overflow-y-auto flex flex-col space-y-3">
                                <SelectButton 
                                    v-for="(p, i) in products"
                                    :key="i"
                                    :parent_match="p"
                                    :active_match="active_match"
                                    :activeNoMatch="parent_product.incheck_reference.no_match_id || ''"
                                    @setActiveMatch="setActiveMatch"
    
                                />
                            </div>
                        </template>
                    </div>
                    <div 
                        v-if="splittedInputSearch.length > 1" 
                        class="flex items-center justify-start space-x-1 dark:text-gray-400">
                        <span>{{ $t('matchesModal.noSatisfaction', $store.state.locale) }}</span>
                        <button 
                            class="text-light-green-500 hover:underline"
                            Title="Click here for the advanced search"
                            @click.prevent="advancedSearch">
                            {{ $t('matchesModal.here', $store.state.locale) }}
                        </button>
                    </div> 
                </div>
                <div class="flex items-center justify-end space-x-3">
                    <!-- No Match Button -->
                    <button 
                        v-if="showNoMatchButton"
                        @click.prevent="setNoMatch"
                        class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
                        :class="setNoMatchButtonClasses"
                        v-html="setNoMatchButtonContet"
                        :disabled="notMatchStatus !== 'void'">
                    </button>
                    <button 
                        @click.prevent="associateProduct"
                        class="px-5 py-3 font-medium leading-5 text-center text-white  rounded lg:mt-0 duration-300 lg:w-auto"
                        :class="setSubmitClasses"
                        :disabled="submitStatus !== 'void'"
                        v-html="setSubmitContent">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

